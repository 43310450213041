/* eslint-disable no-console */
import { fetchData } from '@/services/fetchData';
import { setStories } from '@/store';
import { useDispatch } from 'react-redux';

export const useGetStoryLines = () => {
  const dispatch = useDispatch();

  const getStorylines = async (retryCount = 3) => {
    try {
      const response = await fetchData({
        url: '/me/stories',
      });

      if (response?.status === 200) {
        dispatch(setStories(response.data));
      } else {
        if (retryCount > 0) {
          await getStorylines(retryCount - 1);
        } else {
          console.error('Limited retry count.');
        }
      }
    } catch (error) {
      console.error('Failed getting storylines');
      if (retryCount > 0) {
        await getStorylines(retryCount - 1);
      } else {
        console.error('Limited retry count.');
      }
    }
  };

  return { getStorylines };
};
