/* eslint-disable no-console */
'use client';
import styles from './app.module.scss';
import { ReactNode, Suspense, useEffect, useState } from 'react';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { Loader } from '@/components/Loader/Loader';
import { useGetMe } from '@/hooks/useGetMe';
import { useGetStoryLines } from '@/hooks/useGetStorylines';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectOfferActive,
  selectOfferTimer,
  selectSubscribeModalActive,
  selectUser,
  setOfferTimer,
  setOfferUpgradeModalActive,
  setSubscribeActiveModalActive,
  setSubscribeModalActive,
  setSubscribeSuccessModalActive,
} from '@/store';
import { OFFER_TIMER, SPACE } from '@/constants';
import { useCloseOffer } from '@/hooks/useCloseOffer';
import { useTelegramUser } from '@/hooks/useTelegramUser';
import { useTelegramWebApp } from '@/hooks/useTelegramWebApp';
import { auth } from '@/services/auth';
import { login } from '@/services/login';
import { useGetSubscriptions } from '@/hooks/useGetSubscriptions';
import { useGetUserParams } from '@/hooks/useGetTurnsParams';
import { ButtonGradient } from '@/components/ButtonGradient';

export const App = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const [logged, setLogged] = useState(false);
  const [twaError, setTwaError] = useState(false);
  const { getMe } = useGetMe();
  const { getUserParams } = useGetUserParams();
  const { getStorylines } = useGetStoryLines();
  const { getSubscriptions } = useGetSubscriptions();
  const dispatch = useDispatch();
  const offerActive = useSelector(selectOfferActive);
  const timer = useSelector(selectOfferTimer);
  const { close } = useCloseOffer();
  const user = useTelegramUser();
  const userData = useSelector(selectUser);
  const telegram = useTelegramWebApp();
  const subscribeModalActive = useSelector(selectSubscribeModalActive);
  const [reloadButtonActive, setReloadButtonActive] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const referral = params.get('tgWebAppStartParam');

    const handleAuthorize = async () => {
      await auth(telegram?.initData, referral)
        .then((response) => {
          if (response?.data?.access_token) {
            setLogged(true);
            return;
          }
        })
        .catch((error) => {
          setReloadButtonActive(true);
          console.error(error, 'Failed Authorization');
        });
    };

    const handleDevAuth = async () => {
      await login({ username: 'develop@gmail.com', password: 'develop' })
        .then((response) => {
          if (response?.data?.access_token) {
            localStorage.setItem(
              'botai_access_token',
              response?.data?.access_token,
            );
            setLogged(true);

            return;
          }
        })
        .catch((error) => console.error(error, 'Failed Authorization'));
    };

    if (SPACE === 'DEV') {
      handleDevAuth();
      return;
    }

    if (user) {
      handleAuthorize();
    }

    if (telegram?.initData === '') {
      setTwaError(true);
    }
  }, [user, telegram]);

  useEffect(() => {
    if (logged) {
      getMe()
        .then(() => getStorylines())
        .then(() => getSubscriptions())
        .then(() => getUserParams())
        .then(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged]);

  useEffect(() => {
    if (timer > 0 && offerActive) {
      const timerInterval = setInterval(() => {
        dispatch(setOfferTimer(timer - 1));
        localStorage.setItem(OFFER_TIMER, timer.toString());
      }, 1000);

      return () => clearInterval(timerInterval);
    }

    if (timer === 0) {
      dispatch(setOfferUpgradeModalActive(false));
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer, offerActive]);

  useEffect(() => {
    if (userData && userData.subscribed && subscribeModalActive) {
      dispatch(setSubscribeModalActive(false));
      if (offerActive) {
        dispatch(setSubscribeSuccessModalActive(true));
      } else {
        dispatch(setSubscribeActiveModalActive(true));
      }
    }
  }, [subscribeModalActive, userData, dispatch, offerActive]);

  return (
    <TonConnectUIProvider manifestUrl="https://legends-tma-frontend-prod-264487711799.us-central1.run.app/tonconnect-manifest.json">
      <Suspense fallback={<Loader fullScreen />}>
        <div className={styles.app}>
          <main className={styles.content}>
            {loading && <Loader fullScreen />}
            {loading && reloadButtonActive && (
              <ButtonGradient
                onClick={() => window.location.reload()}
                className={styles.button}
              >
                Reload App
              </ButtonGradient>
            )}
            {loading && twaError && (
              <div className={styles.error}>
                Make sure you logged in via Telegram
                <br />
                or try refreshing the Mini App
              </div>
            )}
            {!loading && children}
          </main>
        </div>
      </Suspense>
    </TonConnectUIProvider>
  );
};
